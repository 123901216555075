@import '~antd/dist/antd.less';

html,
body,
#root {
  height: 100%;
  min-height: 100%;
}

#root {
  .wrapper {
    .ant-layout-sider {
      position: absolute;
      z-index: 5;
      bottom: 0px;
      top: 0px;
      box-shadow: 0 1px 8px #ccc;
      &.ant-layout-sider-collapsed {
        margin-left: -80px;
      }
      .sider-logo {
        padding: 10px 10px 0;
        margin: 0 auto;
      }
    }
    .ant-spin {
      &-nested-loading,
      &-container {
        height: 100%;
      }
    }
    height: 100%;
    .logo {
      width: 340px;
      margin-bottom: 50px;
      display: flex;
      justify-content: center;
      svg {
        width: 100%;
      }
    }
    .header-logo {
      width: 180px;
      display: flex;
      justify-content: center;
      svg {
        width: 100%;
      }
    }
    header {
      padding: 10px 24px;
      height: 90px;
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logout {
        cursor: pointer;
        svg {
          font-size: 20px;
          margin-right: 8px;
        }
        @media screen and (max-width: 575px) {
          .text {
            display: none;
          }
        }
      }
    }
    .main {
      height: 100%;
      .content {
        padding: 15px;
        height: 100%;
      }
    }
    .App {
      height: 100%;
      .App-container {
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100%;
        height: 100%;
        .App-form {
          max-width: 360px;
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: center;
          align-items: center;

          .ant-form {
            width: 100%;

            button {
              width: 100%;
              margin-bottom: 10px;
              text-transform: uppercase;
              font-size: 12px;
            }
          }
        }
      }
    }
    .ant-layout {
      min-height: 100%;
      .alert-term {
        align-items: center;
        position: fixed;
        z-index: 3;
        //right: 24px;
        left: 24px;
        bottom: 24px;
        max-width: 290px;
        &.ant-alert-info {
          background-color: #749699;
          border: none;
          color: #fff;
          .ant-alert-icon {
            color: #fff;
          }
        }
        &.ant-alert-with-description {
          padding: 5px 10px 5px 14px;
        }
      }
      .ant-layout-header {
        background-color: #fff;

        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
          margin: 0 16px;
        }

        .ant-menu {
          width: max-content;
          margin-left: 16px;
          border: none;
          .anticon-exclamation {
            margin: 0;
          }
        }
        .ant-dropdown-trigger {
          margin-left: 10px;
        }
      }

      .ant-layout-footer {
        padding: 12px 0;
        font-size: 13px;
        text-align: center;
      }

      &.container {
        padding: 24px 24px 0;

        .ant-layout-content {
          padding: 24px;
          background: #fff;
        }

        @media screen and (max-width: 575px) {
          padding: 0;

          .ant-layout-content {
            padding: 10px;
          }
        }
      }
      @media screen and (max-width: 575px) {
        height: auto;
      }
      .ant-select {
        width: 100%;
        background: #fff;
      }

      .select-width {
        font-size: 14px;
        width: 300px;
      }

      .margin-top-auto {
        margin-top: auto;
      }

      .ant-table-thead > tr > th {
        background: #a0c8a7;
        font-weight: 600;
      }

      .table-full-width {
        width: 100%;
      }

      .left {
        &__wrapper {
          background: @layout-body-background;
          height: 100%;
          margin-right: 15px;

          @media screen and (max-width: 575px) {
            margin-right: 0;
          }
        }
      }

      .select-flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .bottom-select {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 15px;
      }

      .drop-filter {
        display: flex;
        justify-content: flex-end;
        margin-top: 25px;
        .drop-button {
          margin-left: 10px;
        }
      }

      .ant-row {
        height: 100%;

        &.height-auto {
          height: auto;
        }

        .ant-table-wrapper {
          margin-top: 15px;

          .ant-table-row[data-row-key='totalWallet'] {
            font-weight: 600;
            .ant-table-cell {
              background: rgb(240, 240, 240);
              .anticon-info-circle > svg {
                height: 14px;
              }
            }
          }
        }
        @media screen and (max-width: 575px) {
          .ant-col {
            &.right {
              margin-top: 28px;

              .justify-right,
              .ant-table-wrapper {
                margin-right: 0;
              }
            }
          }
        }
      }

      .actions {
        display: flex;
        flex-wrap: wrap;

        & > span {
          margin-right: 12px;
          display: flex;
          align-items: center;
          svg {
            max-width: 16px;
            font-size: 16px;
          }
          .anticon-eye {
            svg {
              font-size: 30px;
              max-width: 19px;
            }
          }
        }
      }

      .list__wallets {
        overflow: auto;
        .wallet__item {
          padding: 15px 10px;
          background: #fff;
          margin-bottom: 6px;
          border: 1px solid #d9d9d9;
          cursor: pointer;
          position: relative;
          transition: 0.5s ease-out;

          &.active {
            background: @primary-1;
            &:after {
              display: block;
              content: '';
              background: @primary-color;
              position: absolute;
              right: -1px;
              bottom: 0;
              top: 0;
              width: 4px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 480px) {
      .logo {
        width: 320px;
      }
    }
  }
}

.small-underline {
  font-size: 12px;
  text-decoration: underline;
  transition: 0.5s ease;

  &:hover {
    text-decoration: none;
    opacity: 0.9;
  }
}

.primary-color {
  color: @primary-color;
}

.cursor {
  cursor: pointer;
}

.red {
  color: red;
}

.yellow {
  color: #ef9610;
}

.warning {
  color: red !important;
  font-weight: 600;
}

.green {
  color: green;
}

.flex {
  display: flex;
  &.full-width {
    width: 100%;
  }
  &.flex-start {
    justify-content: flex-start;
  }
  &.flex-center {
    justify-content: center;
  }
  &.flex-end {
    justify-content: flex-end !important;
  }
  &.align-items-center {
    align-items: center;
  }
}

.margin_0 {
  margin: 0;
}

.no-scroll {
  overflow-y: hidden;
  position: fixed;
}

.add-user-btn,
.reload-btn {
  margin-top: 25px;
  @media screen and (max-width: 480px) {
    margin: 0;
  }
}

.ant-table {
  .ant-table-row-expand-icon {
    border-radius: 0;
  }
  .anticon {
    &.anticon-caret-up,
    &.anticon-caret-down {
      color: #888;
      &.active {
        color: @primary-color;
      }
    }
  }
}

.table-account {
  .ant-table-thead {
    .ant-table-cell {
      pointer-events: none;
      .ant-table-column-sorter {
        display: none;
      }
    }
  }
}

.term-table {
  .ant-table-tbody > tr {
    cursor: pointer;
  }
  .expandable-wrap {
    margin-left: 40px;
  }
}

.expandable-wrap {
  display: flex;
  justify-content: flex-start;
  margin: 0 30px 0 57px;
  gap: 8px;

  & > div:first-child {
    min-width: 70px;
  }
}

.ant-form {
  &.account {
    background: #fff;
    margin-top: 13px;
    .ant-select {
      margin-bottom: 15px;
    }
  }
}

.ant-row {
  &.ant-form-item {
    &.hidden {
      display: none;
    }
  }

  &.justify-right {
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.ant-form-item {
  .ant-select {
    &.fuel-select {
      width: 100% !important;
    }
    &.fuel-invoice-select {
      width: 50%;
    }
  }
}

.ticket {
  margin-right: 10px;
  margin-top: 5px;
}

.justify-right {
  display: flex;
  justify-content: flex-end;
}

.overflow-auto {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.ant-modal {
  &.invoice {
    max-width: 480px;
  }

  &.transactions {
    //max-width: 780px;
    max-width: 90%;

    .ant-select {
      width: 230px;
    }

    .ant-table-wrapper {
      margin-top: 20px;
    }
    h1 {
      margin: 0;
      font-size: 25px;
      span {
        padding: 5px 10px;
        margin-left: 15px;
        display: inline-flex;
        border: 2px solid;
        cursor: pointer;
        font-size: 20px;
        align-items: center;
        transition: 0.5s ease;

        &:hover {
          opacity: 0.8;
        }
      }
      @media screen and (max-width: 480px) {
        font-size: 20px;
        span {
          display: flex;
          font-size: 20px;
          justify-content: center;
          margin: 15px 0;
        }
      }
    }
  }
}

.radio-transfer {
  display: flex;
  margin-right: 37px;
  label {
    padding: 8px 10px;
    margin-right: 10px;
    cursor: pointer;
    min-width: 58px;
    text-align: center;
    font-weight: 800;
    color: #333;
    background: #e9e9e9;
    transition: 0.5s ease;

    &:hover,
    &.active {
      background: @primary-color;
      color: #fff;
    }

    input {
      display: none;
    }
  }
}

.transaction-period {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .ant-picker {
    width: 100%;
    max-width: 465px;
  }
}

.ant-picker-month-btn {
  text-transform: capitalize;
}

.transaction-actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .refill-actions {
    display: flex;
  }
  @media screen and (max-width: 480px) {
    .refill {
      margin-top: 20px;
    }
  }

  .refill {
    display: flex;
    font-size: 16px;
    align-items: center;
    margin-right: 15px;

    svg {
      max-width: 14px;
    }
  }
}

.transfer-modal {
  .ant-modal-title {
    font-size: 20px;
  }
  .ant-modal-body {
    padding: 15px 16px;
  }
  .ant-table-wrapper {
    margin: 0 -16px;
    max-width: unset;
  }
  .ant-table-container {
    background: #f7f7f7;
    padding: 0 9px;
    .ant-table-thead > tr > th {
      background: #f7f7f7;
      color: #429250;
    }
    .ant-table-cell {
      pointer-events: none;
      .ant-table-column-sorter {
        display: none;
      }
    }
  }

  .ant-table-ping-right:not(.ant-table-has-fix-right)
    .ant-table-container::after,
  .ant-table-ping-left:not(.ant-table-has-fix-left)
    .ant-table-container::before {
    width: 66px;
    box-shadow: none;
    background: linear-gradient(
      273.49deg,
      #f6f6f6 2.67%,
      rgba(246, 246, 246, 0) 96.94%
    );
  }
  .ant-table-ping-left:not(.ant-table-has-fix-left)
    .ant-table-container::before {
    transform: rotate(-180deg);
  }

  .section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    .hr {
      display: flex;
      width: 68%;
      height: 1px;
      background: #f3f0f0;
      margin-left: 10px;
      margin-right: 15px;
      @media screen and (max-width: 480px) {
        width: 55%;
      }
    }
  }
  .amount {
    input {
      max-width: 100px;
    }
  }
  .ant-form {
    .ant-form-item {
      margin-bottom: 10px;

      .ant-btn-primary {
        margin-top: 14px;
        height: 42px;
      }
    }
  }
}

.balance-modal {
  .ant-table-tbody > tr > td {
    border: none;
  }
}

.details {
  display: flex;
  flex-direction: column;

  .details-item {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 10px;

    span {
      flex: 2;

      &.header {
        flex: 1;
        color: #999;
      }
    }
    .balance {
      .item {
        color: @primary-color;
        .amount {
          color: #111;
        }
      }
    }
  }
}

.accounts-option {
  border-bottom: 1px solid #ddd;
  .ant-select-item-option-content {
    display: flex;
    flex-direction: column;
    .header {
      font-weight: 700;
      font-size: 14px;
    }

    .balance {
      display: flex;
      justify-content: space-between;
      .item {
        font-size: 12px;
        color: #999;
        .amount {
          font-weight: 500;
          color: #111;
          &__title {
            color: #999999;
            font-weight: bold;
          }
        }
      }
      .link {
        font-size: 12px;
        color: #429250;
      }
    }
  }
}

.ant-select-selection-item {
  .balance {
    display: none;
  }
}

.dem {
  margin-left: 3px;
  font-size: 14px;
}

.editable {
  display: flex;
  align-items: center;

  .icon {
    margin: 0 8px 0 5px;

    .anticon {
      &.save {
        color: rgb(7, 88, 7);
      }
    }
  }
}

.alias {
  font-size: 12px;
  color: #666;
}

.status {
  font-size: 12px;
}

.greeting {
  margin-right: 5px;
  @media screen and (max-width: 480px) {
    display: none;
  }
}

.default {
  &-button {
    height: 45px;
    margin-top: 5px;
  }
}

.not-found {
  text-align: center;
  h1 {
    font-weight: 600;
    font-size: 112px;
    margin: 60px 0 0;
  }
  h2 {
    font-weight: 500;
    font-size: 28px;
  }
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:last-child {
  display: none;
}

.ant-picker-panel-container,
.ant-picker-footer {
  width: 280px !important;
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}

.new-user-info {
  margin-bottom: 9px;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  .icon {
    margin-right: 4px;
  }
}

.transfer-info-icon {
  margin-left: 4px;
}

.fuel-limit {
  width: 80px;
}

.first-checkbox {
  margin-left: 8px;
}

.invoice-submit {
  display: flex;
  justify-content: flex-end;
}

.ant-select-item-option {
  font-size: 14px;
}

.blockedFuelCard {
  color: #ff0000;
}

.usedFuelCard {
  color: #666666;
}

.refund-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

@media screen and (max-width: 800px) {
  #root {
    .wrapper {
      .ant-layout {
        .ant-layout-header {
          .ant-menu {
            margin-left: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  #root {
    .wrapper {
      header {
        height: 78px;
      }
      .ant-layout {
        .alert-term {
          right: 0;
        }
        .ant-layout-header {
          .ant-menu {
            display: none;
          }
        }
        .ant-layout-footer {
          color: #c0c0c0;
          background: #fff;
        }
      }
    }
  }
  .ant-form-item {
    .ant-select {
      &.fuel-select {
        width: 60%;
      }
    }
  }
}

.fuel-invoice-select {
  width: 50%;
}

@media screen and (max-width: 480px) {
  #root {
    .wrapper {
      .ant-layout {
        .actions {
          & > span {
            svg {
              max-width: 18px;
              font-size: 18px;
            }
            .anticon-eye {
              svg {
                max-width: 21px;
              }
            }
          }
        }
      }
      .term {
        .reload-btn {
          margin-top: 25px;
        }
      }
      .not-found {
        h1 {
          margin-top: 30px;
        }
      }
    }
  }
}

.ant-modal-body .select-width {
  width: 150px;
}

.parties-table {
  .expanded-lvl-2 > .ant-spin-nested-loading >  .ant-spin-container > .ant-table {
    background-color: #effff6;
    margin-left: -16px !important;
  }

  .expanded-lvl-3 {
    .ant-table {
      background-color: #fff8ef;

      tr {
        cursor: pointer;
      }
    }
  }
}

.plastic-cards-table {
  .ant-spin-nested-loading >  .ant-spin-container > .ant-table {
    background-color: #effff6;
  }
}
@primary-color: #429250;@border-radius-base: 6px;@font-size-base: 16px;